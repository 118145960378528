import React, {useEffect, useRef, useState} from 'react'
import FcText from "../../components/atoms/FcText";
import firebase from "firebase"
import {connect} from "react-redux";


const PasswordReset = (props) => {

    const [email, setEmail] = useState('')

    const onEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const onSubmit = (e) => {

        e.preventDefault();
        console.log(e);
        console.log(email);

        firebase.auth().sendPasswordResetEmail(email)

        const mess = 'パスワード再設定用のメールを送信いたしましたので、そちらをご確認ください。'
        props.dispatch({type: 'SET_ALERT' , shown: true, body: mess})
    }


    return (
        <div id={'user-sign-in'}>
            <h1>パスワードの再設定</h1>

            <form onSubmit={onSubmit} className={'signin-form'}>
                <div className="row">
                    <label htmlFor="email">登録メールアドレス</label>
                    <FcText name="email" value={email} onChange={onEmailChange} />
                </div>


                <div className="buttons">
                    <button className={'btn-primary'}>再設定メールを送信</button>
                </div>


            </form>

        </div>
    )
}

export default connect (state => state) (PasswordReset)

