import { Route, Switch } from 'react-router-dom';
import './App.css';

import UserSignUp from './pages/userSignUp/UserSignUp';
import SignUpComplete from './pages/userSignUp/SignUpComplete';


import Index from './pages/Index/Index'
import MapView from "./pages/MapView/MapView"
import ContributionCreate from "./pages/ContributionCreat/ContributionCreate";
import ContributionEdit from "./pages/ContributionEdit/ContributionEdit";
import ContributionView from "./pages/ContributionView/ContributionView";
import UserSignIn from "./pages/UserSignIn/UserSignIn";
import PasswordReset from "./pages/UserSignIn/PasswordReset";
import Auth from "./components/Auth";
import UserEdit from "./pages/UserEdit/UserEdit";


function Routes(props) {

    return (

        <Switch>

            <Route exact path='/' component={Index}/>
            <Route path='/map' component={MapView}/>

            <Route path='/view/:id' component={ContributionView}/>

            <Route path='/sign-in' component={UserSignIn}/>
            <Route path='/sign-up' component={UserSignUp}/>
            <Route path='/sign-up-complete' component={SignUpComplete}/>

            <Route path='/psw-reset' component={PasswordReset}/>


            <Auth auth={props.auth}>
                <Switch>
                    <Route path='/contribute' component={ContributionCreate}/>
                    <Route path='/contribution-edit/:id' component={ContributionEdit}/>
                    <Route path='/user-edit' component={UserEdit}/>
                </Switch>
            </Auth>

        </Switch>

    );
}
export default Routes
