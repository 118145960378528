import React, {useEffect, useState} from 'react'
import * as UserService from "../../services/UserService.js";
import {connect} from "react-redux";
import SetImage from './SetImage'
import * as storage from '../../services/StorageService'
import EditPassword from "./EditPassword";

const UserEdit = (props) => {

    const [user, setUser] = useState(null)
    const [oldData, setOldData] = useState(null)
    const [data, setData] = useState({
        email: '',
        user_name: '',
        description: '',
        new_password: '',
        old_password: '',
    })
    const [editPassword, setEditPassword] = useState(false)

    // Inputにセットされる画像のFIぇオブジェクト
    const [coverImg, setCoverImg] = useState(null)
    const [profileImg, setProfileImg] = useState(null)
    // 初期表示する画像のURL
    const [coverUrl, setCoverUrl] = useState(null)
    const [profileUrl, setProfileUrl] = useState(null)

    const handleInputChange = (e) => {

        const new_data = Object.assign({}, data)
        new_data[e.target.name] = e.target.value
        setData(new_data)
    }

    useEffect(async () => {

        // ローディング画面を表示
        props.dispatch({type: 'SET_LOADING', loading: true})

        const uid = props.authInfo.user.uid

        // ユーザー情報を取得
        const user = await UserService.getAsArray(uid)


        setCoverUrl(user.cover_url)
        setProfileUrl(user.profile_url)

        setUser(user)
        const data = {
            email: user.email,
            user_name: user.user_name,
            description: user.description,
            new_password: '',
            old_password: '',
        }
        setOldData(data)
        setData(data)

        props.dispatch({type: 'SET_LOADING', loading: false})



    }, [])

    const clearForm = () => {

        setEditPassword(false)

        const new_data = Object.assign({}, data)

        new_data.old_password = ''
        new_data.new_password = ''


        setOldData(new_data)
        setData(new_data)

    }

    const onSubmit = async (e) => {

        e.preventDefault();

        props.dispatch({type: 'SET_LOADING', loading: true})


        try {
            if ( data.email !== oldData.email ) {
                await UserService.updateEmail(data.old_password, data.email)
            }
            if ( editPassword ) {
                await UserService.updatePassword(data.old_password, data.new_password)
            }
        } catch (e) {
            const mess = '現在のパスワードが違います。'
            props.dispatch({type: 'SET_LOADING', loading: false})
            props.dispatch({type: 'SET_ALERT' , shown: true, body: mess})
            return
        }

        delete data.new_password
        delete data.old_password

        // カバー画像が変更されていれば処理
        if (coverImg) {
            data.cover_url = await storage.putUserCover(user.uid, coverImg)
        }
        // プロフィール画像が変更されていれば処理
        if (profileImg) {
            data.profile_url = await storage.putUserProfile(user.uid, profileImg)
        }
        // ユーザー情報の更新を処理キューにセット
        await UserService.update(user.uid, data)

        // Reduxストアで格納するAuth情報を更新
        const newUser = await UserService.getAsArray(user.uid)
        const foo = Object.assign({}, newUser)

        const auth = {
            checked: true,
            isAuth: true,
            user: foo,
        }
        console.log(newUser)
        props.dispatch({type: 'UPDATE_AUTH_INFO' , authInfo: auth})



        // フォームの内容をクリア
        clearForm()

        // 完了メッセージの表示
        const mess = 'ユーザー情報を更新しました。'
        props.dispatch({type: 'SET_LOADING', loading: false})
        props.dispatch({type: 'SET_ALERT' , shown: true, body: mess})


        // window.location.reload();


    }



    const handler = e => handleInputChange(e)

    return (

        <div id="user-edit" className={'general-container'}>


            <SetImage
                coverUrl={coverUrl}
                profileUrl={profileUrl}
                onCoverChange={ file => setCoverImg(file)}
                onProfileChange={ file => setProfileImg(file)}
            />


            <form onSubmit={e => onSubmit(e)} id={'user-edit-form'} className={'general'}>


                <div className={"form-row"}>
                    <label htmlFor="email">メールアドレス</label>
                    <div className="input">
                        <input type={'text'} name="email" value={data.email} onChange={handler}/>
                    </div>
                </div>
                <div className={"form-row"}>
                    <label htmlFor="user_name">お名前</label>
                    <div className="input">
                        <input type={'text'} name="user_name" value={data.user_name} onChange={handler} />
                    </div>
                </div>
                <div className={"form-row"}>
                    <label htmlFor="description">自己紹介</label>
                    <div className="input">
                        <textarea name="description" value={data.description} onChange={handler} />
                    </div>
                </div>

                {/*{console.log(editPassword)}*/}


                <EditPassword
                    onChangeActive={ e => setEditPassword(!editPassword) }
                    oldPassword={data.old_password}
                    newPassword={data.new_password}
                    editPassword={editPassword}
                    onInputChange={handler}
                />


                <div className={"form-row"}>
                    <button className={'btn-primary'}>変更する</button>
                </div>

            </form>

        </div>
    )

}
export default connect(state => state)(UserEdit)