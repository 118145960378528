import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';

import {useEffect, useRef, useState} from "react";
import * as UserService from "./services/UserService";
import Header from "./components/blocks/FrontSite/Header";
import {connect} from "react-redux";
import Routes from "./Routes";
import ScrollToTop from "./util/ScrollToTop";
import AlertBox from "./components/blocks/AlertBox";
import LoadingScreen from "./components/blocks/LoadingScreen";
import ScrollLock from "react-scrolllock";


function App(props) {


    const [authState, setAuthState] = useState({
        checked: false,
        isAuth: null,
        user: null,
    })

    const checkAuth = () => {

        UserService.check(async (user) => {

            const state = {};

            if (user){
                const userData = await UserService.getAsArray(user.uid)

                state.checked = true
                state.isAuth = true
                state.user = userData
            } else {
                state.checked = true
                state.isAuth = false
                state.user = null
            }
            setAuthState(state)
            props.dispatch({type: 'UPDATE_AUTH_INFO' , authInfo: state})
        })
    }

    const mainRef = useRef(null);
    props.dispatch({type: 'SET_MAIN_REF' , mainRef: mainRef})

    const wrapperRef = useRef(null);
    props.dispatch({type: 'SET_WRAPPER_REF' , wrapperRef: wrapperRef})


    useEffect( () =>{

        checkAuth()

    },[])


    return (

        <div id="wrapper" ref={wrapperRef}>

            <Router>
                <ScrollToTop>

                    <Header />

                    <main id={'main'} ref={mainRef}>
                        <Routes auth={authState} />
                    </main>


                </ScrollToTop>
            </Router>

            <AlertBox
                shown={props.alert.shown}
                body={props.alert.body}
                onClose={ e => {
                    props.dispatch({type: 'SET_ALERT', shown: false})
                } }
            />
            <LoadingScreen shown={props.loading} />

            <ScrollLock isActive={props.scrolllocked} />
        </div>


    );
}
export default connect((state) => state )(App)
