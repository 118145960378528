import React, {useEffect, useRef, useState} from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as FileService from "../../services/FileService";
import * as G from '../../GlobalResources'


const PickUpList = (props) => {

    const slider = useRef(null)

    const settings = {
        autoplay: false,
        adaptiveHeight: true,
        autoplaySpeed:4000,  // スライド表示時間
        speed: 500,
        dots: true,
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        lazyLoad:"progressive",

    };

    useEffect(()=>{

        const intervalId = setInterval( (e) => {

            slider.current.slickPause()

        }, 1000)

        return () => {
            clearInterval(intervalId)
        };

    }, [])


    const Media = (props) => {

        if ( FileService.isVideo(props.file.meta) ) {

            return (
                <video
                    playsInline
                    className="slide-video slide-media"
                    src={props.file.url + '?playsInline=1'}
                    autoPlay
                    muted
                    loop
                    controls
                    controlsList={'nodownload'}
                />
            )

        } else if ( FileService.isImage(props.file.meta) ) {

            return (
                <figure style={{backgroundImage: "url('" + props.file.url + "')"}}>
                    <img src={props.file.url} className="image-entity"/>
                </figure>
            )
        } else {
            return <></>
        }
    }

    return (
        <div className={'pictures'}>
            <Slider {...settings} className={'slider'} ref={slider}>


                { props.items.map( item => { return (

                    <div className="slide-item" key={item.url}>
                        <div className={'inner'}>

                            <div className="media">
                                <Media file={item} />
                            </div>


                        </div>
                    </div>

                ) })}
            </Slider>
        </div>
    );
}
export default PickUpList
