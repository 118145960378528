import React, {useEffect, useRef, useState} from 'react'
import {connect} from "react-redux";

import axios from 'axios'
import axiosJsonpAdapter from 'axios-jsonp'


const TideInfo = (props) => {

    const [data, setData] = useState(props.data)
    const [tide, setTide] = useState({
        pointName: '',
        averageTide: '',
        totalTide: '',
        siona: '',
        highTide: '',
        highTideTime: '',
        lowTide: '',
        lowTideTime: '',
    })

    const getTideInfo = async () => {


        // 緯度経度が設定されてなかった場合はエラー
        if ( !(data.lat || data.lng) ){
            const mess = '潮汐情報を取得するには釣り場の位置情報を設定してください。'
            props.dispatch({type: 'SET_ALERT' , shown: true, body: mess})

            return
        }

        // APIが受け取れる形式に日付をフォーマット
        const date = datetostr(data.date, 'YYYYMMDD')

        // リクエスト先のURLを生成
        var target = 'https://api.yumake.jp/1.1/tide.php?lat=' + data.lat
        target += '&lon=' + data.lng
        target += '&date=' + date
        target += '&key=ea28dd4fe2bc618f8e6955011aac9dd4&format=jsonp&callback=tideInfoCallback'

        // リクエストを実行
        const res = await axios.get(target, {
            adapter: axiosJsonpAdapter
        })

        if(res.data.status === 'error') {

            const mess = '潮汐情報の取得に失敗しました。'
            props.dispatch({type: 'SET_ALERT' , shown: true, body: mess})

            return
        }

        // ステートを更新
        await setTide({
            pointName: res.data.pointName,
            averageTide: res.data.averageTide,
            totalTide: res.data.totalTide,
            siona: res.data.siona,
            highTide: res.data.highTide,
            highTideTime: res.data.highTideTime,
            lowTideTime: res.data.lowTideTime,
            lowTide: res.data.lowTide,
        })


        // 親コンポーネントに情報を伝達
        if (props.onChange){
            props.onChange(res.data)
        }
    }


    return (
        <div className={'tide-info'}>

            <div className="buttons">

                <button
                    className={'btn-secondary'}
                    type={'button'}
                    onClick={e => getTideInfo(e)}
                >潮汐情報を取得</button>

            </div>

            <table>
                <tbody>
                <tr>
                    <th>該当エリア</th>
                    <td>{tide.pointName}</td>
                </tr>
                <tr>
                    <th>日平均潮位</th>
                    <td>{tide.averageTide}cm</td>
                </tr>
                <tr>
                    <th>日合計潮位</th>
                    <td>{tide.totalTide}cm</td>
                </tr>
                <tr>
                    <th>潮名</th>
                    <td>{tide.siona}</td>
                </tr>
                <tr>
                    <th>満潮時刻</th>
                    <td>
                        {tide.highTideTime[0] && datetostr(tide.highTideTime[0], 'hh:mm')} {tide.highTide[0]}cm <br/>
                        {tide.highTideTime[1] && datetostr(tide.highTideTime[1], 'hh:mm')} {tide.highTide[1]}cm <br/>
                    </td>
                </tr>
                <tr>
                    <th>干潮時刻</th>
                    <td>
                        {tide.lowTideTime[0] && datetostr(tide.lowTideTime[0], 'hh:mm')} {tide.lowTide[0]}cm <br/>
                        {tide.lowTideTime[1] &&datetostr(tide.lowTideTime[1], 'hh:mm')} {tide.lowTide[1]}cm <br/>
                    </td>
                </tr>

                </tbody>
            </table>


        </div>
    )
}

export default connect(state => state)(TideInfo)

const datetostr = (dateStr, format, is12hours) => {

    const date = new Date(dateStr)


    var weekday = ["日", "月", "火", "水", "木", "金", "土"];
    if (!format) {
        format = 'YYYY/MM/DD(WW) hh:mm:dd'
    }
    var year = date.getFullYear();
    var month = (date.getMonth() + 1);
    var day = date.getDate();
    var weekday = weekday[date.getDay()];
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var secounds = date.getSeconds();

    var ampm = hours < 12 ? 'AM' : 'PM';
    if (is12hours) {
        hours = hours % 12;
        hours = (hours != 0) ? hours : 12; // 0時は12時と表示する
    }

    var replaceStrArray =
        {
            'YYYY': year,
            'Y': year,
            'MM': ('0' + (month)).slice(-2),
            'M': month,
            'DD': ('0' + (day)).slice(-2),
            'D': day,
            'WW': weekday,
            'hh': ('0' + hours).slice(-2),
            'h': hours,
            'mm': ('0' + minutes).slice(-2),
            'm': minutes,
            'ss': ('0' + secounds).slice(-2),
            's': secounds,
            'AP': ampm,
        };

    var replaceStr = '(' + Object.keys(replaceStrArray).join('|') + ')';
    var regex = new RegExp(replaceStr, 'g');

    const ret = format.replace(regex, function (str) {
        return replaceStrArray[str];
    });

    return ret;
}

