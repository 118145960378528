import React from 'react'

export default class SignUpComplete extends React.Component{

    constructor (props) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <div id={'sign-up-complete'} className={'general-container'}>
                <p>ユーザー登録が完了いたしました。</p>
                <div className={'buttons'}>
                    <button
                        className={'btn-primary'}
                        onClick={ e => this.props.history.push('/')}
                    >トップページへ進む</button>
                </div>
            </div>

        )
    }
}