import React from 'react'
import FcText from '../../components/atoms/FcText';
import FcTextarea from '../../components/atoms/FcTextarea';

import * as UserService from "../../services/UserService.js";
import Header from "../../components/blocks/FrontSite/Header";
import {onLog} from "firebase";
import FcPassword from "../../components/atoms/FcPassword";
import Validate, {getValidation} from "./Validate";
import {connect} from "react-redux";

class UserSignUp extends React.Component{

    constructor (props) {
        super(props)
        this.state = {

            data: {
                email: '',
                user_name: '',
                description: '',
                password: '',
            },
            validation: getValidation(),

        }
    }

    handleInputChange(e) {
        // ステートから現在のDataを取得
        const data = this.state.data
        // 必要箇所を変更
        data[e.name] = e.value
        // ステートを更新
        this.setState({ data: data })
    }

    onSubmit(e) {

        e.preventDefault();

        // バリデーションを実行
        const validation = Validate(this.state.data)
        // validation情報をstateにセット
        this.setState({validation: validation})

        // 入力値にエラーがあれば処理を中止
        if ( validation.hasError ) {
            // 入力フォームのトップまでスクロール
            this.props.wrapperRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            })
            return false
        }

        UserService.create(this.state.data).then( res => {
            this.props.history.push("/sign-up-complete");
        })
    }

    render() {

        const handler = e => this.handleInputChange(e)

        return (
            <div id="user-sign-up" className={'general-container'}>
                <form onSubmit={e => this.onSubmit(e)} id={'sign-up-form'} className={'general'}>

                    <div className={"form-row"}>
                        <label htmlFor="email">メールアドレス</label>
                        <div className="input">
                            <FcText name="email" value={this.state.data.email} onChange={handler} />
                            {this.state.validation.errors.email && (

                                <div className="error">{this.state.validation.errors.email}</div>
                            ) }
                        </div>
                    </div>
                    <div className={"form-row"}>
                        <label htmlFor="password">パスワード</label>
                        <div className="input">
                            <FcPassword name="password" value={this.state.data.password} onChange={handler} />
                            {this.state.validation.errors.password && (

                                <div className="error">{this.state.validation.errors.password}</div>
                            ) }
                        </div>
                    </div>
                    <div className={"form-row"}>
                        <label htmlFor="user_name">ユーザー名（サービス内で公開）</label>
                        <div className="input">
                            <FcText name="user_name" value={this.state.data.user_name} onChange={handler} />
                            {this.state.validation.errors.user_name && (

                                <div className="error">{this.state.validation.errors.user_name}</div>
                            ) }
                        </div>
                    </div>
                    <div className={"form-row"}>
                        <label htmlFor="description">自己紹介</label>
                        <div className="input">
                            <FcTextarea name="description" value={this.state.data.description} onChange={handler} />
                        </div>
                    </div>


                    <div className={"form-row"}>
                        <button className={'btn-primary'}>登録する</button>
                    </div>

                </form>
            </div>
        )
    }
}
export default connect(state => state) (UserSignUp)